import { Box, Grid } from "@mui/material";
import React from "react";

import styles from "../styles/General.module.css";
import Img from "../assets/images/heroBG.png";
import Logo from "../assets/logo/logo.png";
import { Link } from "react-router-dom";

const Thankyou = () => {
  const customStyle = {
    backgroundImage: `url(${Img}) `,
    width: "100%",
    // maxWidth: "470px",
    maxHeight: "460px",
    // minWidth: "390px",
    minHeight: "382px",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div id="hero-section" style={customStyle} className={styles.heroSection}>
      <img src={Logo} width="145" height="36" style={{ marginTop: 28 }} />
      <h1>Thank you</h1>
      <Link to="/" style={{ textDecoration: "none" }}>
        <h2 style={{ color: "white" }}>Reserve another class</h2>{" "}
      </Link>
    </div>
  );
};

export default Thankyou;
