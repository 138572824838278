import React, { useRef, useState } from "react";
import styles from "../../../styles/General.module.css";
import Male from "../../../assets/images/male.png";
import Female from "../../../assets/images/female.png";
import Arrow from "../../../assets/images/arrow.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Form = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    ref,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      dateOfBirth: "",
      phone: "",
      gender: "",
      dates: props.room ? null : props.dates,
      class_id: props.room ? null : props.id,
      room_id: props.room ? props.id : null,
      start: props.room ? props.start : null,
      end: props.room ? props.end : null,
    },
  });
  // const onSubmit = (data) => console.log(data);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        props.room
          ? `${process.env.REACT_APP_API_BASE_URL}/user/book`
          : `${process.env.REACT_APP_API_BASE_URL}/user/reserve`,
        data
      )
      .then((res) => {
        navigate("/thank-you", { replace: true });
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const [optionSelected, setOptionSelected] = useState(0);

  const inputRef = useRef();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.inputs}>
        <input
          placeholder="Full name"
          {...register("fullName", { required: true })}
        />
        <input
          placeholder="Email Address"
          {...register("email", { required: true })}
        />
        <input
          ref={inputRef}
          placeholder="Date of birth"
          type="text"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          {...register("dateOfBirth", { required: true })}
        />

        <input
          placeholder="Phone Number"
          {...register("phone", { required: true })}
        />
      </div>
      <div>
        <div className={styles.genderOptions}>
          <div
            onClick={() => {
              setValue("gender", 1);
              setOptionSelected(1);
            }}
            className={optionSelected == 1 ? styles.optionSelected : null}
          >
            <img src={Male} />
            <p>Male</p>
          </div>
          <div
            onClick={() => {
              setValue("gender", 2);
              setOptionSelected(2);
            }}
            className={optionSelected == 2 ? styles.optionSelected : null}
          >
            <img src={Female} />
            <p>Female</p>
          </div>
        </div>
        <div className={styles.reserveBtn} onClick={handleSubmit(onSubmit)}>
          <p>Reserve class</p>
          <span>
            {loading ? <div class="spinner"></div> : <img src={Arrow} />}
          </span>
        </div>
      </div>
    </form>
  );
};

export default Form;
