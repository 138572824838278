import React from "react";
import Hero from "./sections/Hero";
import AllRooms from "./sections/AllRooms";
import { useLocation } from "react-router-dom";

const Rooms = () => {
  return (
    <>
      <Hero />
      <AllRooms />
    </>
  );
};

export default Rooms;
