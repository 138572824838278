import { Box, Grid } from "@mui/material";
import React from "react";

import styles from "../../../styles/General.module.css";
import Img from "../../../assets/images/heroBG.png";
import Logo from "../../../assets/logo/logo.png";
const Hero = () => {
  const customStyle = {
    backgroundImage: `url(${Img}) `,
    width: "100%",
    // maxWidth: "470px",
    maxHeight: "460px",
    // minWidth: "390px",
    minHeight: "382px",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div id="hero-section" style={customStyle} className={styles.heroSection}>
      <img src={Logo} width="145" height="36" style={{ marginTop: 28 }} />
      <h1>Pick Category </h1>
    </div>
  );
};

export default Hero;
