import React, { useState } from "react";
import { createBrowserHistory } from "history";
import cookie from "js-cookie";

import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/home";
import { Container } from "@mui/material";
import Class from "./pages/class";
import Reservation from "./pages/reservation";
import Thankyou from "./pages/thankyou";
import Classes from "./pages/classes";
import Rooms from "./pages/rooms";
import Room from "./pages/room";

const hist = createBrowserHistory();

function App() {
  const [user, setUser] = useState("");
  const token = cookie.get("token");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const Loader = () => (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <BrowserRouter history={hist}>
      <React.Suspense fallback={<Loader />} />
      <ToastContainer />
      {/* <Layout> */}
      <div
        style={{
          width: "100%",
          padding: 0,
          textAlign: "center",
          placeContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        // maxWidth="md"
      >
        <ReactRoutes>
          <Route path="*" element={<Home />} />
          <Route path="/classes/:id" element={<Classes />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/room/:id" element={<Room />} />
          <Route path="/class/:id" element={<Class />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/thank-you" element={<Thankyou />} />
        </ReactRoutes>
      </div>
      {/* </Layout> */}
    </BrowserRouter>
  );
}

export default App;
