import React, { useMemo } from "react";
import styles from "../styles/General.module.css";
import Arrow from "../assets/images/arrow.png";
import { Link } from "react-router-dom";
const ClassCard = (props) => {
  const { id, image, name, smallDescription, bigDescription, price } =
    props.data;

  return (
    <Link
      style={{ display: "flex" }}
      className={styles.classCard}
      to={
        props.rooms
          ? "rooms"
          : props.room
          ? `/room/${id}`
          : props.category
          ? `/classes/${id}`
          : `/class/${id}`
      }
    >
      <img
        width="180"
        height="155"
        src={
          props.rooms
            ? image
            : `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${image}`
        }
        alt="image"
      />
      <div className={styles.classInfo}>
        <h1>{props.category ? name : smallDescription}</h1>
        {props.category ? (
          <img src={Arrow} style={{ marginLeft: 23 }} />
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h2>
                {price} <span>USD</span>
              </h2>
              <h3>{props.room ? "Night" : "Session"}</h3>{" "}
            </div>
            <span>
              <img src={Arrow} style={{ marginLeft: 23 }} />
            </span>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ClassCard;
