import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../customHooks/useFetch";

import ClassCalendar from "./sections/ClassCalendar";
import Hero from "./sections/Hero";

const Room = () => {
  const { pathname } = useLocation();

  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/room`,
    { id: pathname.split("room/")[1], withBookings: true }
  );

  return (
    <>
      <Hero />
      <ClassCalendar data={data} room={true} />
    </>
  );
};

export default Room;
