import React from "react";
import Hero from "./sections/Hero";
import Activities from "./sections/Activities";
import Categories from "./sections/Categories";

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Activities /> */}
      <Categories />
    </>
  );
};

export default Home;
