import React from "react";
import Hero from "./sections/Hero";
import Activities from "./sections/Activities";
import { useLocation } from "react-router-dom";

const Classes = () => {
  const { pathname } = useLocation();
  const id = pathname.split("classes/")[1];
  return (
    <>
      <Hero />
      <Activities id={id} />
    </>
  );
};

export default Classes;
