import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import Arrow from "../../../assets/images/arrow.png";
import styles from "../../../styles/General.module.css";
import { Link } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
const ClassCalendar = ({ data, room }) => {
  const days = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 0, name: "Sunday" },
  ];

  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const allDaysArr = data?.days?.split(",") ?? [];
  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState([]);

  const [classTime, setClassTime] = useState("");
  const [amPm, setAmPm] = useState("AM");
  function formatAMPM(classTime) {
    if (!data.id) return;
    var hours = classTime?.split(":")[0];
    var minutes = classTime?.split(":")[1];

    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes?.length < 2 ? "0" + minutes : minutes;

    return `<p>${hours} : ${minutes} <span>${ampm}<span><p>`;
  }

  const [bookedDates, setBookedDates] = useState([]);
  const [newBooking, setNewBooking] = useState([]);
  useEffect(() => {
    if (room) {
      const allBookings = data?.bookings?.map((b) => [
        new Date(b.start).setHours(0, 0, 0, 0),
        new Date(b.end).setHours(0, 0, 0, 0),
      ]);

      setSelected(allBookings);

      const alreadyBookedDates = [];
      data?.bookings?.map((b) => {
        const datesInRange = getDatesInRange(b.start, b.end);
        alreadyBookedDates.push(...datesInRange);
      });
      // console.log(alreadyBookedDates);
      setBookedDates(alreadyBookedDates);
    }
  }, [data?.id]);

  function getDatesInRange(startDate, endDate) {
    const start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
    const end = new Date(new Date(endDate).setUTCHours(0, 0, 0, 0));

    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(new Date(date).setHours(0, 0, 0, 0));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  // useEffect(() => {
  //   const dates = data?.reservations?.map(
  //     (reservation) => new Date(reservation.reservationDate)
  //   );
  //   setValues(dates);
  //   formatAMPM();
  // }, [data?.id]);

  const [limit, setLimit] = useState(false);
  const setDates = (selectedDates) => {
    if (room) {
      let valid = true;

      const start = selectedDates[selectedDates.length - 1][0];
      const end = selectedDates[selectedDates.length - 1][1];

      if (!start || !end) return;

      const startDate = new Date(start).setHours(0, 0, 0, 0);
      const endDate = new Date(end).setHours(0, 0, 0, 0);

      data?.bookings.map((booking) => {
        const bookingStart = new Date(booking.start).setHours(0, 0, 0, 0);
        const bookingEnd = new Date(booking.end).setHours(0, 0, 0, 0);

        if (bookingStart < endDate && startDate < bookingEnd) {
          console.log("The date ranges overlap.");
          valid = false;
        } else {
          // console.log("The date ranges do not overlap.");
        }
      });

      if (valid) {
        setNewBooking({
          start: start,
          end: end,
        });

        const allBookings = data?.bookings?.map((b) => [
          new Date(b.start).setHours(0, 0, 0, 0),
          new Date(b.end).setHours(0, 0, 0, 0),
        ]);

        setSelected([...allBookings, [start, end]]);
      } else {
        const allBookings = data?.bookings?.map((b) => [
          new Date(b.start).setHours(0, 0, 0, 0),
          new Date(b.end).setHours(0, 0, 0, 0),
        ]);

        setSelected([...allBookings, [newBooking.start, newBooking.end]]);
        console.log("Already reserved");
      }
    } else {
      if (selectedDates?.length == data.reservationLimit) {
        setLimit(true);
      } else {
        setLimit(false);
      }
      if (selectedDates?.length <= data.reservationLimit) {
        setSelected(selectedDates);
      } else {
      }
    }
  };

  return (
    <>
      <div className={styles.classCalendar}>
        <div style={{ display: "flex" }} className={styles.classMiniCard}>
          <img
            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${data?.image}`}
            alt="image"
          />
          <div className={styles.classMiniInfo}>
            <h1>{data?.smallDescription}</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h2>
                  {data?.price} <span>USD</span>
                </h2>
                <h3>{room ? "Night" : "Session"}</h3>{" "}
              </div>
            </div>
          </div>
        </div>

        <Calendar
          className={styles.calendar}
          minDate={tomorrow}
          multiple
          value={selected ? selected : []}
          // value={selected.map((x) => new Date(x).setHours(0, 0, 0, 0))}
          onChange={(dates) => setDates(dates)}
          mapDays={({ date }) => {
            let props = {};
            if (allDaysArr.includes(date.weekDay.index.toString()) || room)
              if (room) {
                if (
                  !bookedDates.includes(new Date(date).setHours(0, 0, 0, 0))
                ) {
                  props.style = {
                    ...props.style,
                    background: "rgba(147, 135, 167, 0.5)",
                    pointerEvents: "auto",
                  };
                }
              } else {
                props.style = {
                  ...props.style,
                  background: "rgba(147, 135, 167, 0.5)",
                  pointerEvents: !limit && "auto",
                };
              }

            return props;
          }}
          range={room}
          rangeHover={room}
        />
      </div>

      <div className={styles.classesTimes}>
        {data?.classTime &&
          JSON.parse(data?.classTime)?.map(
            (time, index) =>
              time.start && (
                <div
                  className={styles.instructorCard}
                  to={`/reservation`}
                  state={{
                    dates: selected?.map((x) =>
                      new Date(x)
                        .toLocaleDateString("pt-br")
                        .split("/")
                        .reverse()
                        .join("-")
                    ),

                    id: data.id,
                  }}
                >
                  <p className={styles.classDay}>
                    <span>{days[index].name}</span>
                  </p>
                  <div
                    className={styles.classTime}
                    dangerouslySetInnerHTML={{ __html: formatAMPM(time.start) }}
                  />

                  <p className={styles.classInstructor}>
                    <span>INSTRUCTOR</span>

                    <span>{data?.teacher?.name}</span>
                  </p>
                  {/* <img src={Arrow} /> */}
                </div>
              )
          )}
      </div>

      <Link
        className={styles.reserveLink}
        to={selected?.length > 0 ? `/reservation` : "/"}
        state={
          room
            ? {
                start: new Date(newBooking.start)
                  .toLocaleDateString("pt-br")
                  .split("/")
                  .reverse()
                  .join("-"),

                end: new Date(newBooking.end)
                  .toLocaleDateString("pt-br")
                  .split("/")
                  .reverse()
                  .join("-"),
                id: data.id,
                room: true,
              }
            : {
                dates: selected?.map((x) =>
                  new Date(x)
                    .toLocaleDateString("pt-br")
                    .split("/")
                    .reverse()
                    .join("-")
                ),

                id: data.id,
              }
        }
      >
        <span> Reserve </span> <img src={Arrow} />
      </Link>
    </>
  );
};

export default ClassCalendar;
