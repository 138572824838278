import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import Arrow from "../../../assets/images/arrow.png";
import styles from "../../../styles/General.module.css";
import { Link } from "react-router-dom";
const ClassCalendar = ({ data }) => {
  const days = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 0, name: "Sunday" },
  ];

  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const allDaysArr = data?.days?.split(",") ?? [];
  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState([]);

  const [classTime, setClassTime] = useState("");
  const [amPm, setAmPm] = useState("AM");
  function formatAMPM(classTime) {
    if (!data.id) return;
    var hours = classTime?.split(":")[0];
    var minutes = classTime?.split(":")[1];

    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes?.length < 2 ? "0" + minutes : minutes;

    return `<p>${hours} : ${minutes} <span>${ampm}<span><p>`;
  }

  useEffect(() => {
    const dates = data?.reservations?.map(
      (reservation) => new Date(reservation.reservationDate)
    );

    setValues(dates);
    formatAMPM();
  }, [data?.id]);

  const [limit, setLimit] = useState(false);
  const setDates = (values) => {
    if (values.length == data.reservationLimit) {
      setLimit(true);
    } else {
      setLimit(false);
    }
    if (values.length <= data.reservationLimit) {
      setSelected(values);
    } else {
    }
  };

  return (
    <>
      <div className={styles.classCalendar}>
        <div style={{ display: "flex" }} className={styles.classMiniCard}>
          <img
            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${data?.image}`}
            alt="image"
          />
          <div className={styles.classMiniInfo}>
            <h1>{data?.smallDescription}</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h2>
                  {data?.price} <span>USD</span>
                </h2>
                <h3>Session</h3>{" "}
              </div>
            </div>
          </div>
        </div>

        <Calendar
          className={styles.calendar}
          minDate={tomorrow}
          multiple
          value={selected}
          // value={selected.map((x) => new Date(x).setHours(0, 0, 0, 0))}
          onChange={(dates) => setDates(dates)}
          mapDays={({ date }) => {
            let props = {};
            if (allDaysArr.includes(date.weekDay.index.toString()))
              props.style = {
                ...props.style,
                background: "rgba(147, 135, 167, 0.5)",
                pointerEvents: !limit && "auto",
              };
            return props;
          }}
        />
      </div>

      <div className={styles.classesTimes}>
        {data?.classTime &&
          JSON.parse(data?.classTime).map(
            (time, index) =>
              time.start && (
                <div
                  className={styles.instructorCard}
                  to={`/reservation`}
                  state={{
                    dates: selected.map((x) =>
                      new Date(x)
                        .toLocaleDateString("pt-br")
                        .split("/")
                        .reverse()
                        .join("-")
                    ),

                    id: data.id,
                  }}
                >
                  <p className={styles.classDay}>
                    <span>{days[index].name}</span>
                  </p>
                  <div
                    className={styles.classTime}
                    dangerouslySetInnerHTML={{ __html: formatAMPM(time.start) }}
                  />

                  <p className={styles.classInstructor}>
                    <span>INSTRUCTOR</span>

                    <span>{data?.teacher?.name}</span>
                  </p>
                  {/* <img src={Arrow} /> */}
                </div>
              )
          )}
      </div>

      <Link
        className={styles.reserveLink}
        to={selected.length > 0 ? `/reservation` : "/"}
        state={{
          dates: selected.map((x) =>
            new Date(x)
              .toLocaleDateString("pt-br")
              .split("/")
              .reverse()
              .join("-")
          ),

          id: data.id,
        }}
      >
        <span> Reserve </span> <img src={Arrow} />
      </Link>
    </>
  );
};

export default ClassCalendar;
