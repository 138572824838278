import React from "react";
import useFetch from "../../../customHooks/useFetch";
import ClassCard from "../../../components/ClassCard";
const Activities = ({ id }) => {
  const { data, error, loading } = useFetch(
    `${process.env.REACT_APP_API_BASE_URL}/data/classes`,
    { id: id }
  );

  return (
    <div
      style={{
        transform: "translateY(-100px)",
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "800px",
      }}
    >
      {data?.map((rec, index) => (
        <ClassCard key={index} data={rec} />
      ))}
    </div>
  );
};

export default Activities;
