import React from "react";
import { useLocation } from "react-router-dom";
import Hero from "./sections/Hero";
import Form from "./sections/Form";

const Reservation = () => {
  const { state } = useLocation();
  console.log("state:", state);

  return (
    <>
      <Hero />
      <Form
        dates={state.dates}
        id={state.id}
        room={state.room}
        start={state.start}
        end={state.end}
      />
    </>
  );
};

export default Reservation;
